html, :root {
  padding: 0;
  margin: 0;
  background-color: #282c34;
}


.App {
  text-align: center;
}

.App-header {
  min-height: 20vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  margin-left: 10px;
  margin-right: 10px;
  background: none;
  border: none;
  transition: transform 0.3s ease;
  color: azure;
}

.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.App-list {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
}

.App-row {
  display: flex;
  justify-content: center;
  gap: 0px; /* Adjust the gap between buttons as needed */
  margin-bottom: 0px; /* Space between rows */
}

.App-list button:nth-child(6n) {
  margin-right: 100%;
}

.App-content iframe {
  border: none;
  width: 80%;
  height: 80vh;
}

.App-logo {
  max-width: 100px; /* Adjust the size as needed */
  margin-bottom: 20px;
}

.icon{
  height: 70%;
  width: 90%;
}